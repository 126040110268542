<template>
	<footer class="app-footer">
		<div class="container-content">
			<div class="container-two-1">
				<div class="container-left">
					<div class="left-img">
						<img @click="pushTo" src="./img/Login.png" />
						<p>企业硬件解决方案领先品牌</p>
					</div>
					<!-- <div class="left-text">
						<p>武汉宁美互联科技股份有限公司</p>
						<p>湖北省武汉市东湖新技术开发区光谷大道61号光谷智慧园28栋</p>
					</div> -->
					<div class="left-text">
						<p class="left-text-one">
							<span>咨询热线：</span>
							<!--<span>400-7786-686</span>
							<span>027-59322922</span>-->
							<span>027 5932 2922  ( 9:00-18:00 )</span>
						</p>
						<!--<p>08:30 ~ 18:00（周一至周六）</p>-->
						<p>公司地址：湖北省武汉市江夏区光谷智慧园28栋</p>
					</div>
				</div>
				<div class="container-right">
					<div class="right-text-l">
						<p>我们的产品</p>
						<router-link :to="`/desktop/${2}`"><a style="color: #999999">台式机</a></router-link>
						<router-link :to="`/desktop/${3}`"><a style="color: #999999">笔记本</a></router-link>
						<router-link :to="`/desktop/${4}`"><a style="color: #999999">工作站</a></router-link>
						<router-link :to="`/desktop/${5}`"><a style="color: #999999">一体机</a></router-link>
						<!--<router-link :to="`/desktop/${6}`"><a>mini电脑</a></router-link>-->
						<router-link :to="`/desktop/${9}`"><a style="color: #999999">服务器</a></router-link>
						<div>&nbsp;</div>
					</div>
					<div class="right-text-c">
						<p>我们的服务</p>
						<a href="/#/support">服务与支持中心</a>
						<a href="/#/aboutUs">了解我们</a>
						<router-link :to="`/putsomeing?page=1`" target="_blank"
						><span>用户协议</span></router-link
						>
						<router-link :to="`/putsomeing?page=2`" target="_blank"
						><span>隐私政策</span></router-link
						>
					</div>
					<div class="right-text-img">
						<p>企业微信</p>
						<img src="../assets/banner/code1.jpg" alt="" />
					</div>
					<div class="right-text-img">
						<p>微信小程序</p>
						<img src="../assets/banner/code2.png" alt="" />
					</div>
					<!-- <div class="right-text-r">
						<p>联系我们</p>
						<p>400-7786-686</p>
						<p>周一 ~ 周六</p>
						<p>08:30 ~ 18：00</p>
					</div> -->
				</div>
			</div>
			<div class="btn-text-t">
				<p>
					<a class="a-1" href="javascript:">© 2024 宁美</a>
					<!-- <a href="javascript:">ICP经营许可证：鄂866555522</a> -->
					<a
							class="a-2"
							target="_blank"
							href="https://beian.miit.gov.cn/#/Integrated/recordQuery"
					>鄂ICP备2024041931号-2</a
					>
					<a
							class="a-3"
							target="_blank"
							href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=42018502006875"
					>
						<img
								src="../assets/banner/jingwei.png"
								style="
                position: relative;
                left: 0px;
                bottom: 2px;
                text-decoration: none;
              "
						/>
					</a>
					<a
							class="a-2"
							target="_blank"
							href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=42018502007458"
					>
						鄂公网安备 42018502007458号</a
					>

					<a class="a-2" href="javascript:" @click="dialogVisible1 = true"
					>增值电信业务经营许可证：鄂B2-20240685</a
					>
					<a class="a-2" href="javascript:" @click="dialogVisible = true"
					>营业执照</a
					>
					<a class="a-2" href="https://xyt.xcc.cn/getpcInfo?sn=1814305516986241024&language=CN&certType=8&url=*.ningmeipc.com" target="_blank" style="position: relative;bottom:-15px;display: inline-block;height: 38px;">
						<div style="width:100%;height:100%;position: absolute;top: 0;left: 0;"></div>
						<embed src="https://program.xinchacha.com/web/1814305516986241024=*.ningmeipc.com.svg" width="103" height="38" type="image/svg+xml" pluginspage="//www.adobe.com/svg/viewer/install/"/>
					</a>
				</p>
			</div>
		</div>
	</footer>
	<el-dialog v-model="dialogVisible">
		<img style="width: 100%" :src="require('../assets/license.jpg')" />
	</el-dialog>
	<el-dialog
			v-model="dialogVisible1"
			style="height: 900px; width: 620px; --el-dialog-margin-top: 1vh"
	>
		<img
				style="height: 800px"
				:src="require('../components/img/telecommunications.jpg')"
		/>
	</el-dialog>
</template>

<script setup>
import { useRouter } from "vue-router";
import { ref } from "vue";
const dialogVisible = ref(false);
const dialogVisible1 = ref(false);
const router = useRouter();
const pushTo = () => {
	router.push("/");
};
</script>

<style lang="less" scoped>
.app-footer {
	//width: 100%;
	width: 1920px;
	margin: 0 auto;
	min-height: 260px;
	color: #ffffff;
	background-color: #000000;
	.container-content {
		width: 1920px;
		.container-two-1 {
			display: flex;
			.container-left {
				margin-left: 240px;
				margin-top: 32px;
				margin-right: 232px;
				.left-img {
					img {
						width: 100px;
						height: 50px;
						margin-bottom: 14px;
					}
					p {
						width: 336px;
						font-size: 28px;
						font-family: Microsoft YaHei;
						font-weight: bold;
						color: #ffffff;
						margin-bottom: 37px;
					}
				}
				.left-text {
					.left-text-one {
						//width: 240px;
						margin-bottom: 14px;
						span{
							font-size: 14px;
							font-family: Microsoft YaHei;
							font-weight: 400;
							color: #999999;
							margin-bottom: 19px;
						}
						/*:nth-child(1) {
							font-size: 14px;
							font-family: Microsoft YaHei;
							font-weight: 400;
							color: #000000;
							margin-bottom: 19px;
						}
						:nth-child(2) {
							font-size: 24px;
							font-family: Microsoft YaHei;
							font-weight: bold;
							color: #000000;
						}
						:nth-child(3) {
							font-size: 24px;
							font-family: Microsoft YaHei;
							font-weight: bold;
							color: #000000;
							margin-left: 28px;
						}*/
					}
					:nth-child(2) {
						width: 398px;
						font-size: 14px;
						font-family: Microsoft YaHei;
						font-weight: 400;
						color: #999999;
						margin-bottom: 20px;
					}
					:nth-child(3) {
						width: 398px;
						font-size: 14px;
						font-family: Microsoft YaHei;
						font-weight: 400;
						color: #999999;
						margin-bottom: 31px;
					}
				}
			}
			.container-right {
				display: flex;
				.right-text-l {
					margin-top: 32px;
					margin-right: 99px;
					p {
						width: 100px;
						font-size: 20px;
						font-family: Microsoft YaHei;
						font-weight: bold;
						color: #ffffff;
						margin-bottom: 24px;
					}
					> a {
						display: block;
						font-size: 13px;
						font-family: Microsoft YaHei;
						font-weight: 400;
						color: #000000;
						margin-bottom: 14px;
					}
					a:hover {
						color: #CE1220 !important;
					}
				}
				.right-text-c {
					width: 100px;
					margin-top: 32px;
					margin-right: 98px;
					p {
						width: 100px;
						font-size: 20px;
						font-family: Microsoft YaHei;
						font-weight: bold;
						color: #ffffff;
						margin-bottom: 24px;
					}
					> a {
						display: block;
						font-size: 13px;
						font-family: Microsoft YaHei;
						font-weight: 400;
						color: #999999;
						margin-bottom: 14px;
						&:hover {
							color: #CE1220;
						}
					}
				}
				.right-text-img {
					width: 162px;
					margin-top: 32px;
					margin-right: 45px;
					p {
						width: 100px;
						font-size: 20px;
						font-family: Microsoft YaHei;
						font-weight: bold;
						color: #ffffff;
						margin-bottom: 24px;
						/*font-size: 16px;
						font-family: Microsoft YaHei;
						font-weight: 400;
						color: #000000;
						margin-bottom: 20px;*/
					}
					img {
						width: 162px;
						height: 164px;
					}
				}
				.right-text-r {
					width: 198px;
					margin-top: 32px;
					:nth-child(1) {
						font-size: 16px;
						font-family: Microsoft YaHei;
						font-weight: 400;
						color: #ffffff;
						margin-bottom: 33px;
					}
					:nth-child(2) {
						width: 198px;
						font-size: 28px;
						font-family: Microsoft YaHei;
						font-weight: bold;
						color: #ffffff;
						margin-bottom: 29px;
					}
					:nth-child(3) {
						font-size: 16px;
						font-family: Microsoft YaHei;
						font-weight: 400;
						color: #ffffff;
						margin-bottom: 10px;
					}
					:nth-child(4) {
						font-size: 16px;
						font-family: Microsoft YaHei;
						font-weight: 400;
						color: #ffffff;
					}
				}
			}
		}
		.btn-text-t {
			//width: 930px;
			//margin-left: 504px;
			display: flex;
			justify-content: center;
			padding-bottom: 15px;
			p {
				.a-1 {
					font-size: 12px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #999999;
					margin-right: 44px;
					cursor: default;
				}
				.a-3 {
					font-size: 12px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					text-decoration: underline;
					color: #999999;
					&:hover {
						color: #CE1220;
					}
				}
				.a-2 {
					font-size: 12px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					text-decoration: underline;
					color: #999999;
					margin-right: 44px;
					&:hover {
						color: #CE1220;
					}
				}
			}
		}
	}
}
</style>
